// Card Shaddow 
.shadowCard {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
}

.dayName {
  display: inline-block;
  width: 85px;
}

.currentDay {
  width: 85px;
  font-weight: bold;
}

.schedule.popover {
  max-width: fit-content;
  gap: 6px;
  
  .ocTime {
    display: flex;
    column-gap: 15px;
  } 
  .multipleSlots {
    display: flex;
    flex-direction: column;
  }
}

// =========== END - Page Banner's Style =================



