@import "src/scss/_colors.scss";

.howItWorks {
  .slick-arrow {
    display: none !important;
  }

  .slider-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 50px;

    .textWrap {
      width: 100%;
      // gap: 20px !important;
    }
  }

  .slider-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .custom-arrow {
      background: $arrowBg;
      border-radius: 100%;
      width: 70px;
      height: 70px;
      display: flex !important;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        background-color: darken($color: $arrowBg, $amount: 7);
      }
      @media screen and (max-width: 767px) {
        width: 55px;
        height: 55px;
      }
    }
    @media screen and (max-width: 992px) {
      width: 100%;
      justify-content: end;
    }
  }
}
