.expired {
  color: rgb(220, 53, 69);
  font-size: 12.25px;
}

.resend {
  cursor: pointer;
  color: #6C5CE7;
  padding-bottom: 10px;
}
