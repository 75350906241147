// carousel Styles

#carouselMenuItems{
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: row;
  column-gap: 24px;
  align-items: flex-start;
  @media screen and (max-width: 767px) {

  }


  .carousel-indicators{
    position: relative;
    z-index: auto;
    width: 222px;
    margin: 0;
    padding: 0;
    list-style: none;
    flex-wrap: wrap;
    justify-content: start;
    gap: 10px;
    @media screen and (max-width: 992px) { width: 190px;}
    @media screen and (max-width: 767px) { display: none;}
    >li{
      background-color: #e5e5e5;
      width:47%;
      height: 80px;
      margin: 0;
      background-clip: border-box;
      border-radius: 10px;
      border: 0;
      opacity: 0.9;
      position: relative;
      overflow: hidden;
      @media screen and (max-width: 992px) { height: 60px;}

      &::after{
        position: absolute;
        display: block;
        top: 0;
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.5);
        z-index: 9;
        opacity: 0;
        
      }
      &.active{
        &::after{ opacity: 1;}
      }
      
      img{
        width: 100%;
        height: 100%;
        display: block;
        object-fit: contain;
      }
    }
  }

  .carousel-inner{
    flex: 1;
    background-color: #000;
    .carousel-item{
      
      img{
        width: auto;
        max-width: 100%;
        height: auto;
        position: relative;
        max-height: 550px;
        left: 50%;
        transform: translateX(-50%);
        min-height: 420px;
        object-fit: contain;
      }
    }
    
    
  }
}


