.aCard {
  padding: 0 15px;
  color: white;

  a {
    color: white;
  }

  &:hover {
    .bgImage {
      transform: translate(-50%, -50%) scale(1.1);
    }

    .holder {
      &::after {
        transition: all 0.3s ease;
        // background-color: rgba($color: #000000, $alpha: 0.6);
        background-color: rgba($color: #000000, $alpha: 0.35);
      }

      p {
        >span {
          transform: translateX(3px);
          position: relative;
        }
      }
    }
  }

  .imgWrap {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .bgImage {
    position: relative;
    transition: all ease 0.3s;
    top: 50%;
    left: 50%;
    min-width: 100%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%) scale(1);
    object-fit: fill;
    display: block;

  }

  .holder {
    height: 100px;
    overflow: hidden;
    border-radius: 8px;
    min-height: 220px;
    padding: 32px 23px;
    background: linear-gradient(180deg, #9C75CD 0%, #7D70E1 100%);
    transition: all ease 0.2s;
    position: relative;

    @media screen and (max-width: 992px) {
      min-height: 180px;
    }

    @media screen and (max-width: 767px) {
      height: auto;
      padding: 14px;
    }

    &::after {
      display: block;
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
      background: linear-gradient(180deg, rgba(44, 44, 44, 0.023) 0%, rgba(43, 43, 43, 0.086) 0.01%, #1e204175 100%);
      z-index: 1;
      transition: all 0.3s ease;
    }

    &:hover {
      .link {
        transform: scale(1.2);
      }
    }

    h2 {
      color: #FFF;
      text-align: center;
      font-family: Inter;
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: 38px;
      /* 146.154% */
      text-transform: capitalize;

      margin-top: 30px;
      margin-bottom: 18px;
      text-transform: capitalize;
      // letter-spacing: 0.7px;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
      display: -webkit-box;
      -webkit-line-clamp: 2;
      /* Number of lines to show */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      @media screen and (max-width: 992px) {
        font-size: 22px;
        line-height: normal;
      }

      @media screen and (max-width: 767px) {
        // font-size: 18px;
        // margin-top: 15px;
      }
    }

    p {
      color: white;
      font-size: 14px;
      font-weight: 300;
      line-height: 1;
      letter-spacing: 0.8px;
      opacity: 0.8;
      display: flex;
      column-gap: 8px;

      >span {
        transition: all 0.3s ease;
      }

      @media screen and (max-width: 767px) {
        font-size: 12px;
      }
    }
  }

  .iconArea {
    width: 64px;
    height: 64px;
    padding: 2px;
    margin-bottom: 30px;

    svg {
      width: auto;
      height: auto;
    }
  }

  p {
    font-size: 14px;
    line-height: 23px;
  }

  .link {
    color: inherit;
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    text-transform: capitalize;
    text-decoration: none;
    transition: all ease 0.2s;

    img {
      width: auto;
      margin-left: 4px;
    }
  }
}