@import '/src/scss/colors';

.successModal {
    text-align: center;
    .modal-body,
    .modal-header,
    .modal-footer{ justify-content: center; text-align: center; display: block;}
    .modal-header{
      color: $text-color;
      h1 {
        font-size: 28px;
        line-height: normal;
        font-weight: 500;
      }
      h3 {
        font-size: 16px;
        line-height: normal;
        font-weight: normal;
        color: $text-color;
      }
      >img{
        margin-bottom: 30px;
      }
    }
}

