@import '/src/scss/colors';

.modal {
  
    .modal-title {
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 5px;
    }
  
    .modal-header {
      flex-direction: column;
      margin-bottom: 15px;
  
      h2 {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0;
      }
    }
  
    .modal-body {
  
      p {
        margin: 0;
      }
  
      .grayText {
        color: #828282;
      }

      .ratingWrap {
        display: flex;
        flex-wrap: wrap;
        column-gap: 7px;
        margin: 8px 0 20px;

        .form-label {
          color: $darkGray;
          font-size: 14px;
          margin-bottom: 0;
          margin-top: 3px;
        }

        .text-danger {
          margin-top: 3px;
        }
      }
    }
    .wCount{
      text-align: right;
      margin-top: 6px;
    }
  
    // Modal Footer
    .modal-footer {
      justify-content: start;
      column-gap: 15px;
  
      button {
        min-width: 132px;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        padding: 12px 20px;
        margin: 0;
      }

      .btn.btn-secondary {
        background-color: transparent;
        color: #6C5CE7;
        border: 1px solid #6C5CE7;
        min-width: auto;
      }
    }
  }

