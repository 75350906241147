@import "src/scss/_colors.scss";

header {
  position: relative;
  z-index: 2;
  background-color: #fff;
  border-bottom: 1px solid #EDEDED;

  .dropdown>button:hover {
    border: none !important;

  }

  .dropdown {
    .dropdown-menu {
      min-width: 230px;
      max-width: 230px;
      overflow: hidden;

      @media screen and (min-width: 1024px) {
        min-width: 280px;
      }

      .dropdown-item {
        padding: 8px 15px;

        p {
          font-family: inter;
          font-size: 14px;
          font-weight: 500;
          margin: 0;
          line-height: normal;
          color: $text-color;
          margin-bottom: -2px;
        }

        white-space: wrap;

      }
    }
  }

  .navbar {
    .btn-group {
      display: flex;
      gap: 20px;
      align-items: center;

      >span {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .stats {
        font-size: 11px;
        color: $darkGray;
      }

      .howItWorks,
      .btn-secondary,
      .btn-secondary>span {
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;

        @media screen and (max-width: 560px) {
          display: none;
        }
      }

      .howItWorks {
        white-space: nowrap;
        overflow: unset;
      }

      .btn-secondary {
        padding: 0;
        border: none;
        box-sizing: border-box !important;
        // min-height: auto;
        // color: #484848 !important;
        border: transparent !important;

        &.show {
          &:hover,
          &:active,
          &:visited,
          &:focus-within {
            background-color: transparent !important;
            border: transparent !important;
          }
        }

        &:hover,
        &:active,
        &:visited,
        &:focus-within,
        &.show,
        &:focus {
          background-color: transparent !important;
          color: unset !important;
          border: transparent !important;
          color: darken($color: #000000, $amount: 0);
          filter: brightness(100%);
        }
      }
    }
  }

  .container-fluid {
    min-height: 80px;
    position: sticky;
    padding: 0 30px;
    top: 0;

    @media screen and (max-width: 992px) {
      min-height: 70px;
    }

    @media screen and (max-width: 767px) {
      min-height: 60px;
      padding: 0 20px;
    }
  }

  // Navigation
  .navbar-nav {
    gap: 20px;
  }

  .nav-item {
    font-weight: 600;
    font-size: 16px;

    >a.nav-link {
      padding: 0;
      color: $text-color;
      font-weight: 600;

      &:hover,
      &.active,
      &.active {
        color: $primary;
      }
    }
  }

  .headerbutton {
    margin-left: 10px;
    display: flex;
    column-gap: 5px;

    @media screen and (min-width: 768px) {
      column-gap: 10px;
      margin-left: 36px;
    }
  }

  #loginButton {
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    border-radius: 8px;
    padding: 8px 15px;
    width: auto;
    min-width: 92px;
    margin-left: 15px;

    @media screen and (max-width: 767px) {
      font-size: 12px;
      padding: 5px 10px;
    }
  }

  .logNum {
    >button {
      font-size: 14px;
      padding: 0;
      border: 0;
      height: auto;

      &:hover,
      &:active,
      &.show {
        background-color: transparent !important;
        border: 0 !important;
        color: $text-color;
      }
    }

    .dropdown-menu {
      font-size: 14px;
    }
  }
}

// Logo
.navbar-brand {
  width: 110px;
  height: auto;
  padding: 0;
  margin-top: -5px;

  @media screen and (max-width: 992px) {
    width: 110px;
  }

  @media screen and (max-width: 767px) {
    width: 90px;
  }

  img {
    width: 100%;
    height: auto;
  }
}