@import "src/scss/_mixins.scss", "src/scss/_colors.scss";

.shopDetailPage {
  background-color: white;
}

.vendorDetail {
  .vendorDetailsWrap {
    background: $primarySweet;
    padding:10px 0 70px;

    @media screen and (max-width: 992px) {
      border-radius: 0px 0px 20px 20px;
      padding: 0 0 30px;
    }
    @media screen and (max-width: 767px) {
      border-radius: 0px 0px 10px 10px;
      padding: 0 0 20px;
    }

    .head {
      display: flex;
      justify-content: end;
      column-gap: 20px;
      margin-bottom: 40px;
      align-items: end;
      @media screen and (max-width: 767px) { column-gap: 15px;}

      .thumbnailHolder {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        aspect-ratio: 1/1;
        overflow: hidden;
        border: 4px solid #fff;
        border-radius: 8px;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
        @media screen and (max-width: 767px) {
          width: 80px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          -o-object-fit: cover;
          &.default{
            width: 40%;
            height: auto;
          }
        }
      }

      .nameHolder {
        flex: 1;
      }

      h1 {
        color: $text-color;
        font-size: 32px;
        font-weight: 500;
        // text-transform: capitalize;
        margin: 0;

        @media screen and (max-width: 992px) {
          // font-size: 38px;
        }
        @media screen and (max-width: 767px) {
          // font-size: 32px;
        }
      }

      p {
        font-size: 14px;
        color: grey;
        margin: 5px 0;
        cursor: pointer;
      }

      .metaData {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 6px;
        color: $text-color;
        font-size: 14px;
        line-height: normal;
        .avgRating{ font-weight: 500;}
      }
      .star-ratings{
        margin-top: -5px;
      }
      .reviews{
        >a{color: $darkGray ;}
        
      }
    }

    .vendorMapWrap {
      z-index: 2;
      @media screen and (max-width: 992px) {
        margin-top: 30px;
        // margin-bottom: -200px;
      }
      // @media screen and (max-width: 767px) {
      
      // }

      .holder {
        min-height: 350px;
        height: 100%;
        border-radius: 10px;
        border: 4px solid var(--White, #FFF);
        background: #f7f7f7;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.30);
        -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.30);
        overflow: hidden;
        @media screen and (max-width: 767px) { min-height: auto;}

        > a {
          display: block;
          height: 100%;
        }
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }


    // Social Icon for Detail page
    .socialIcons {
      display: flex;
      column-gap: 12px !important;
      justify-items: baseline;

      > a {
        width: auto;
        display: flex;
        justify-items: center;
      }

      img {
        width: 20px;
        height: auto;
      }
    }

    .CTA {
      column-gap: 20px;
      flex-wrap: wrap;

      button {
        flex: 1;
        padding: 15px 30px;
        font-size: 18px;
        letter-spacing: 1px;
        font-weight: 500;
      }
    }
  }

  // Text Wrap styles
  .detailsWrap {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    row-gap: 40px;

    .dService {
      padding: 6px 15px;
      border-radius: 20px;
      background: #b5aef40d;
      border: 0;
      font-size: 14px;
      min-width: 170px;
      font-weight: normal;
      display: flex;
      border: 1px solid #6c5de72e;
      gap: 4px;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 767px) { min-width: auto;}
    }

    h1 {
      color: $text-color;
      font-size: 48px;
      font-style: normal;
      font-weight: 300;
      text-transform: capitalize;
      margin: 0 0 22px;
      @media screen and (max-width: 992px) {
        font-size: 38px;
      }
      @media screen and (max-width: 767px) {
        font-size: 22px !important;
        margin-bottom: 10px !important;
      }
    }

    #vListData {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      row-gap: 22px;
      @media screen and (max-width: 992px) {
        row-gap: 18px;
      }
      @media screen and (max-width: 767px) {
        row-gap: 16px;
        font-size: 14px;
      }

      li {
        display: flex;
        column-gap: 8px;
        color: $text-color;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        opacity: 0;
        transform: translateX(-50px);
        transition: all 0.3s ease;

        &.show {
          transform: translateX(0);
          opacity: 1;
        }

        a {
          color: $text-color;
        }

        .chevron {
          width: 13px;
          height: 21px;
          margin-top: 2px;
          cursor: pointer;
        }
      }
    }
  }

  // ======== detailSections Styles

  .detailSections {
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    row-gap: 70px;
    @media screen and (max-width: 992px) {
      padding: 40px 0;
      row-gap: 50px;
    }
    @media screen and (max-width: 767px) {
      // padding-top: 210px;
      // padding-bottom: 20px;
      row-gap: 40px;
    }

    h2 {
      font-size: 22px;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 15px;
      @media screen and (max-width: 767px) {
        font-size: 18px;
        margin-bottom: 10px;
      }

      > span {
        font-size: 16px;
        font-weight: 400;
        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
      }
    }

    // carousel =====================
    .carouselWrap {
      position: relative;

      .carousel {
        display: flex;
        align-items: stretch;
        gap: 20px;
        list-style: none;
        padding: 10px;
        margin: 0;
        overflow: auto;
        width: unset !important;

        // Hide browser's default Scroll for this slider
        &::-webkit-scrollbar {
          display: none;
        }
      }

      .slide {
        border-radius: 8px;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        min-width: 190px;
        max-width: 230px;
        // height: 300px;
        background-color: $text-color;
        height: auto;
        overflow: hidden;
        @media screen and (max-width: 992px) {
          width: 150px;
          height: 260px;
        }
        @media screen and (max-width: 767px) {
          width: 100px;
          height: 170px;
        }

        > div {
          display: block;
          height: 100%;
          cursor: pointer;
        }

        img {
          width: 100%;
          height: 100%;
          max-height: 300px;
          object-fit: contain;
          transition: all 0.3s ease;
        }

        &:hover {
          img {
            transform: scale(1.04);
          }
        }
      }

      .next,
      .prev {
        position: absolute;
        content: '';
        display: block;
        z-index: 1;
        width: 70px;
        height: 70px;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        border-radius: 100px;
        border: 1px solid rgba(0, 0, 0, 0.44);
        background: rgba(0, 0, 0, 0.50);
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        transition: all ease 0.3s;
        border: 1px solid rgba($color: #fff, $alpha: 0.2);

        &:hover {
          background: rgba(0, 0, 0, 0.80);
        }

        svg {
          width: 25%;
        }
      }

      .prev {
        right: auto;
        left: 10px;
      }
    }

    // All Items =====================
    .allItems {
      display: flex;
      gap: 29.5px;
      margin: 0;
      padding: 0;
      list-style: none;
      flex-wrap: wrap;
      row-gap: 70px;

      @media (max-width: 1100px) {
        row-gap: 8px;
        justify-content: space-evenly;
      }

      @media (max-width: 767px) {
        row-gap: 8px;
      }

      li {
        width: 155px;
        min-height: 215px;
        position: relative;
        text-transform: capitalize;
        text-align: center;
        justify-content: space-between;

        @media (max-width: 767px) {
          width: 130px;
        }

        .productImageWrap {
          overflow: hidden;
          position: relative;
          height: 175px;
          border-radius: 8px;
          background-color: $lightGrey;

          @media (max-width: 767px) {
            height: 140px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;

            &.default {
              width: 70px;
              height: auto;
              position: relative;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }

        }

        .productDetail {
          display: flex;
          flex-direction: column;
          row-gap: 3px;
          margin-top: 8px;

          > span {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;

            &.price {
              color: $priceColor;
            }

          }
        }

        div {
          display: flex;
          flex-direction: column;
          row-gap: 3px;
          margin-top: 8px;
        }

      }

      // offers and Discount
      .offerTag {
        padding: 4px 25px;
        font-size: 12px;
        gap: 10px;
        margin: 0;
      }
    }

    // famous items
    .famousItems {
      border-radius: 15px;
      background: rgba(108, 92, 231, 0.10);
      padding: 25px 20px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;

      ul {
        margin: 0;
        // padding: 0;
        // list-style: none;
        display: flex;
        column-gap: 40px;

        li {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
        }
      }
    }
  }

  .placeholder {
    color: $formBorder
  }

  .dServicePlaceholder {
    color: $formBorder;
    border-radius: 20px; 
    height: 35px; 
    width: 170px;
  }

  // ########## Maryam Styles ##############

  #carouselMenuItems {
    width: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: row;
    column-gap: 24px;
    align-items: start;
  }

  #logout:hover {
    cursor: pointer;
  }

  .noResults {
    font-size: 20px;
  }

  #lastUpdated {
    display: block;
    margin: -10px 0 10px;
    color: $darkGray;
    font-size: 14px;
  }

  .menuLinkContainer {
    width: 100%;
    height: 100%;
    padding: 30px;
    background: white;
    border-radius: 8px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    display: block;
    ul{
      padding: 0;
    }
  }

  .linkList {
    margin: 10px;
  }

  .menuLink {
    font-weight: normal;
    color: #6C5CE7;
  }

  .searchfield {
    display: inline-flex;
    position: relative;
  }

  .form-control {
    padding-right: 30px;
  }

  .clear-icon {
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    cursor: pointer;
    display: none;
  }

  @media (max-width: 767px) {
    .clear-icon {
      right: 5px;
    }
  }

  #map {
    width: 100%;
    height: 100%;
  }

  .reviewWrap {
    padding: 50px 0;
    position: relative;
    z-index: 1;

    h2 {
      font-size: 22px;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 15px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 6px;
      @media screen and (max-width: 767px) {
        font-size: 18px;
        margin-bottom: 10px;
      }

      > span {
        font-size: 16px;
        font-weight: 500;
        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
      }
    }
    .reviewCount{
      color: $darkGray;
      font-weight: normal;
    }

    .container {
      position: relative;
    }

    &::before {
      position: absolute;
      display: block;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .reviewHolder {
      flex: 1;
    }

    .wrap{
      align-items: start;
      @media screen and (max-width: 992px) {
        gap: 15px;
      }
    }
    .reviewsList {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      row-gap: 15px;
      font-size: 14px;
      line-height: normal;

      > li {
        display: flex;
        width: 100%;
        padding: 18px 15px;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 8px;
        // border-bottom: 1px solid var(--Gray-lighter, #E8E8E8);
        background: #FFF;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);

        > .head {
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;

          > div {
            display: flex;
            column-gap: 10px;
            align-items: center;

            .star-ratings {
              margin-bottom: 6px;
            }

            .rating {
              margin-bottom: 3px;
              color: $darkGray;

              @media screen and (max-width: 380px) {
                font-size: 10px;
              }
            
            }

            .avatar {
              display: flex;
              overflow: hidden;
              border-radius: 100px;
              justify-content: center;
              align-items: center;
              width: 34px;
              height: 34px;
              background-color: #E8E8E8;
              @media screen and (max-width: 767px) {
                display: none;
              }
              

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .nickName {
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              text-transform: capitalize;
            }
          }

          .dateArea {
            color: $darkGray;
            display: flex;
            column-gap: 10px;
            font-size: 12px;
           
            @media screen and (max-width: 380px) {
              font-size: 8px;
            }
            
            .edited{
              display: flex;
              column-gap: 10px;
              align-items: center;
              >.divider{
                display: flex;
                width: 1px;
                height: 15px;
                background-color: $darkGray;
              }
            }
          }
        }

        .reviewText {
          padding-left: 45px;
          white-space: pre-line;
          @media screen and (max-width: 767px) {
            padding: 10px 0 0;
          }
        }
      }
    }

    .writeReview {
      display: flex;
      height: 100%;
      min-height: 500px;
      padding: 30px;
      flex-direction: column;
      justify-content: space-between;
      background: #FFF;
      box-shadow: -4px 4px 8px 0px rgba(0, 0, 0, 0.06);

      h5 {
        font-size: 16px;
        line-height: normal;
        font-weight: 400;
        margin-bottom: 10px;
        color: $darkGray;
      }

      h4 {
        font-size: 20px;
        line-height: normal;
        font-weight: 400;
        margin-bottom: 30px;
      }

      img {
        margin: 0 auto 30px;
        max-width: 225px;
        height: auto;

      }

      .btn {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 100%;
        max-width: 350px;
        margin: 0 auto;
        height: 50px;
      }
    }
  }

  .allSections{
    section{
      &:nth-child(2n){
        background-color: $primarySweet;
      }
    } 
  }
}

.edited .popover-arrow::after {
  border-bottom-color: $popover !important;
}
