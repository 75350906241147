//  breadcrumb Styles
.breadcrumb{
  margin-bottom: 0;
}
.breadcrumbWrap{
  padding: 20px 0;
  font-size: 14px;
  background-color: $primarySweet;
  .breadcrumb{ margin: 0;}
  
  .breadcrumb-item{
    text-transform: capitalize;
    a{
      color: $breadcrumb-light;
    }
    &:first-child{
      &::before{ display: none;}
    }
    &::before{
      display:block;
      //background: url(../images/breadcrumbArrow.svg) no-repeat center center;
      content: "";
      height: 100%;
      width: 8px;
      padding: 0 !important;
      margin-right: 8px;
      position: relative;
    }
    &.active{ 
      color: $breadcrumb-dark;
      &::before{
        color: $breadcrumb-light;
      }
    }
    &.breadcrumb-item::before {
      color: $breadcrumb-light;
    }
  }
}

.breadcrumbWrap.placeOrder {
  background-color: $bodyBg;
  margin-bottom: 20px;
  @media screen and (max-width: 900px) {
    font-size: 12px;
    padding: 5px 0;
  }
}
