@import "src/scss/_colors.scss";

.show-more-text {
  position: relative;

  .text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .text.expanded {
    -webkit-line-clamp: unset;
  }

  .read-more {
    color: $primary;
    cursor: pointer;
    font-weight: 500;
  }

  .edited {
    font-size: 12px;
    color: gray;
  }
}
