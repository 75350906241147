// Form Element styles

input,tel, select{
  height: 50px;
  border-radius: 8px;
  padding: 8px 15px;
  color: $text-color;

&::placeholder{ color: #CACACA !important;}
  &:focus{
   box-shadow:0 0 0 0.25rem rgba(108, 92, 231, 0.10);
  }
}

textarea.form-control{
  border-radius: 8px;
  border: 1px solid #D0D1D3;
  background: #FFF;
  font-size: 16px;
  padding: 15px;
  min-height: 150px;
  &::placeholder{ color: #CACACA;}
  &:focus{
    box-shadow:0 0 0 0.25rem rgba(108, 92, 231, 0.10);
   }
}
