@import "src/scss/_mixins.scss", "src/scss/_colors.scss";

.home {
  font-family: "Inter", sans-serif;

  // Hero Banner
  .heroBanner {

    font-family: "Roboto Condensed", sans-serif;
    // display: flex;
    overflow: hidden;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 67%, rgba(252, 229, 253, 0.25) 84%, rgba(193, 177, 241, 0.25) 100%), #FFF;

    > .holder {
      display: flex;
      min-height: calc(100vh - 80px);
      padding: 50px 0;
      background: url("../../assets/images/heroBg.png") no-repeat 100% 100%;

      @media screen and (max-width: 1441px) {
        padding: 30px 0;
        background-size: 60%;
        min-height: calc(100vh - 70px);
      }

      @media screen and (max-width: 767px) {
        padding: 30px 0 50px;
        min-height: calc(100vh - 60px);
      }
    }

    .container {
      align-content: center;

      .row {
        display: flex;
        height: 100%;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
    }

    .tag {
      display: flex;
      flex-direction: column;
      width: auto;
      padding: 7px 15px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 18px;
      border: 1px solid #B6B6B6;
      color: var(--darkGray, #5f5d5d);
      text-align: center;
      font-family: "Roboto Condensed";
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin-bottom: 40px;
      @media screen and (max-width: 1441px) {
        margin-bottom: 30px;
        font-size: 12px;
      }
      @media screen and (max-width: 360px) {
        margin-bottom: 20px;
      }
    }

    .textWrap {
      display: flex;
      flex-direction: column;
      gap: 30px;
      margin-bottom: 50px;
      @media screen and (max-width: 1441px) {
        margin-bottom: 30px;
      }

      @media screen and (max-width: 767px) {
        gap: 20px;
      }

    }

    h1 {
      color: #6F63CF;
      font-family: "Roboto Condensed";
      font-size: 60px;
      font-style: normal;
      font-weight: 700;
      line-height: 60px;
      letter-spacing: -1.2px;
      text-transform: capitalize;
      margin-bottom: 0;

      @media screen and (max-width: 1441px) {
        font-size: 45px;
        line-height: 50px;
      }

      @media screen and (max-width: 767px) {
        font-size: 30px;
        line-height: 40px;
      }
      @media screen and (max-width: 360px) {
        font-size: 28px;
        line-height: 36px;
      }

      span {
        color: #616161;
        font-size: 45px;
        font-style: normal;
        font-weight: 400;
        line-height: 63px;
        letter-spacing: -0.9px;

        @media screen and (max-width: 1441px) {
          font-size: 35px;
          line-height: 53px;
        }

        @media screen and (max-width: 767px) {
          font-size: 23px;
          line-height: 33px;
        }
      }
    }

    h2 {
      color: #616161;
      font-family: "Roboto Condensed";
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: 30px;
      margin-bottom: 0;

      @media screen and (max-width: 767px) {
        font-size: 18px;
        line-height: 26px;
      }
    }

    .heroButton {
      display: flex;
      min-width: 350px;
      padding: 19px 30px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      background: linear-gradient(90deg, #6C5CE7 0%, #88568C 100%);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.18);
      color: white;
      text-align: center;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      cursor: pointer;
      @media screen and (max-width: 1441px) {
        min-width: 300px;
        padding: 15px 30px;
        font-size: 18px;
      }

      @media screen and (max-width: 767px) {
        font-size: 16px;
        padding: 15px 20px;
        min-width: 280px;
      }

      &:hover {
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
      }
    }

    .urduWrapper {
      display: inline-flex;
      padding: 20px 93px 20px 33px;
      justify-content: center;
      align-items: center;
      gap: 40px;
      border-radius: var(--Number, 0px) var(--Number, 0px) 210px var(--Number, 0px);
      background: rgba(111, 99, 207, 0.05);
      font-size: 18px;
      line-height: normal;
      @media screen and (max-width: 1441px) {
        
        padding: 20px 70px 20px 33px;
        gap: 40px;
        background: rgba(111, 99, 207, 0.05);
        font-size: 16px;
        line-height: normal;
      }
      @media screen and (max-width: 767px) {
        padding-right: 50px;
        font-size: 14px;
        align-items: start;
      }
      @media screen and (max-width: 360px) {
       padding: 20px 40px 20px 20px;

      }
    }


  }
  // ##########
  // ##########

  // Areas Section
  .areasWrap {
    min-height: calc(100vh - 150px);
    padding: 100px 0;
    background-color: white;

    @media screen and (max-width: 992px) {
      padding: 50px 0;
      min-height: calc(100vh);
    }

    @media screen and (max-width: 767px) {
      padding: 40px 0;
    }

    .spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 15rem;
    }

    .head {
      margin-bottom: 40px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;

      @media screen and (max-width: 992px) {
        margin-bottom: 40px;
      }

      @media screen and (max-width: 767px) {
        margin-bottom: 30px;
      }

      > p {
        font-size: 18px;
        margin: 0 auto;
        // max-width: 900px;
        color: $darkGray;
        color: #636262;

        @media screen and (max-width: 992px) {
          font-size: 16px;
        }

        @media screen and (max-width: 767px) {
          padding: 0;
          line-height: normal;
        }
      }

      .areaTab {
        color: #4D5156;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: flex;
        padding: 6px 30px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #FFF;
        background: #F0EFEF;
      }
    }

    h1 {
      font-size: 36px;
      color: #333;
      color: $text-color;
      letter-spacing: -1px;
      margin: 0;
      font-size: 36px;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;

      @media screen and (max-width: 992px) {
        font-size: 32px;
        font-weight: 600;
      }

      @media screen and (max-width: 767px) {
        font-size: 26px;
        line-height: 32px;
        // padding: 0 15px;
      }
    }
  }

  // Explore Cards section and cards
  .cityCards {
    row-gap: 30px;

    @media screen and (max-width: 767px) {
      row-gap: 15px;
    }
  }

  .placeholderContainer {
    padding: 0 15px;
    justify-content: space-around;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    row-gap: 30px;

    .outer {
      width: 356px; 
      height: 220px; 
      color: $formBorder;

      .inner {
         height: 100%; 
         border-radius: 8px;
      }
    }
  }

  // How it works Section styles
  .howItWorks {
    overflow: hidden;

    .slick-track {
      display: flex;
      flex-direction: row;
    }

    .slick-list {
      position: relative;
      z-index: 1;
    }

    .slide {
      color: white;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      position: relative;

      @media screen and (max-width: 767px) {
        padding: 20px 0 130px;
      }

      &.slide1 {
        background: var(--HowWork-Slide-1, linear-gradient(90deg, #85A1CC 0%, #9290CA 100%));
      }

      &.slide2 {
        background: var(--HowWork-Slide-2, linear-gradient(90deg, #68BBAC 0%, #688EBB 100%));
      }

      &.slide3 {
        background: var(--HowWork-Slide-3, linear-gradient(90deg, #A28CBD 0%, #7582C5 100%));
      }

      &.slide4 {
        background: var(--HowWork-Slide-4, linear-gradient(90deg, #7FC6D0 0%, #8C9FC5 100%));
      }

      &.slide5 {
        background: var(--HowWork-Slide-5, linear-gradient(270deg, #6FBDB4 0%, #6BBBC0 100%));
      }

      .imgWrap {
        @media screen and (max-width: 767px) {
          width: 30%;
          height: 85px;
          img{
            height: 100%;
            width: auto;
          }
        }

        img {
          max-height: 246px;
          width: 100%;
          height: auto;
        }
      }

      .textWrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 50px;

        @media screen and (max-width: 992px) {
          flex: 1;
        }

        @media screen and (max-width: 767px) {
          gap: 20px;
        }

        .title {
          color: #FFF;
          font-size: 28px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: capitalize;

          @media screen and (max-width: 767px) {
            font-size: 22px;
          }
        }

        .contentArea {
          display: flex;
          width: 100%;
          min-height: 227px;
          padding: 40px;
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;
          flex-shrink: 0;
          border-radius: 8px;
          background: rgba(254, 254, 254, 0.80);
          @media screen and (max-width: 767px) {
            padding: 30px;
          }


          h4 {
            color: #333;
            font-size: 24px;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.48px;

            @media screen and (max-width: 767px) {
              font-size: 20px;
              line-height: 26px;
            }
          }

          p {
            color: #484848;
            font-size: 18px;
            font-weight: 400;
            line-height: 28px;

            /* 155.556% */
            @media screen and (max-width: 767px) {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }

        .controls {
          display: flex;
          align-items: center;
          gap: 13px;

          @media screen and (max-width: 767px) {
            align-self: flex-end;
          }

          .control {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 70px;
            height: 70px;
            border-radius: 100%;
            background: #333;
            cursor: pointer;

            @media screen and (max-width: 767px) {
              width: 65px;
              height: 65px;
            }
          }
        }
      }

      .countWrap {
        display: flex;
        // width: 100%;
        padding-bottom: 0;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        @media screen and (max-width: 992px) {
          position: absolute;
          left: 90px;
          right: auto;
          bottom: 70px;
          width: auto;
        }

        @media screen and (max-width: 767px) {
          left: 30px;
          right: auto;
        }

        .countTitle {
          color: rgba(255, 255, 255, 0.90);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          /* 137.5% */
          text-transform: capitalize;

          @media screen and (max-width: 992px) {
            font-size: 14px;
            line-height: 20px;
          }
        }

        .countNum {
          color: rgba(255, 255, 255, 0.15);
          font-size: 220px;
          font-style: normal;
          font-weight: 300;
          line-height: 220px;

          @media screen and (max-width: 992px) {
            font-size: 170px;
            line-height: 180px;
          }

          @media screen and (max-width: 767px) {
            font-size: 90px;
            line-height: 80px;
          }

          /* 100% */
        }
      }
    }

    .holder {
      display: flex;
      align-items: center;
      gap: 33px;
      align-self: stretch;

      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }
  }

  // Stats Section Styles
  .statsSection {
    padding: 80px 0;
    background: linear-gradient(180deg, #818DC9 0%, #B896C8 100%);
    color: white;

    @media screen and (max-width: 992px) {
      padding: 50px 0;
    }

    .head {
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 60px;

      @media screen and (max-width: 992px) {
        font-size: 16px;
        margin-bottom: 30px;
      }

      h2 {
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.10);
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.72px;

        @media screen and (max-width: 992px) {
          font-size: 32px;
        }
        @media screen and (max-width: 767px) {
          font-size: 26px;
          line-height: 32px;
        }
      }
    }

    .statsHolder {
      display: flex;
      justify-content: center;
      row-gap: 20px;
      flex-wrap: wrap;

      .card {
        display: flex;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        color: $text-color;
        font-weight: 400;
        line-height: 28px;
        padding: 50px 30px;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        // flex: 1 0 0;
        border-radius: 10px;
        background: white;
        min-height: 100%;

        @media screen and (max-width: 767px) {
          padding: 30px 20px;
        }

        .head {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10px;
          align-self: stretch;
          margin-bottom: 24px;

          h5 {
            color: #8781B1;
            text-align: center;
            font-size: 70px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin: 0;

            @media screen and (max-width: 767px) {
              font-size: 55px;
            }

            > span {
              color: #8781B1;
              font-size: 60px;
              font-style: normal;
              font-weight: 300;
              line-height: normal;
            }
          }

          span {
            color: #7b7b7b;
            text-align: center;
            font-size: 26px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }

        p {
          span {
            color: #867fba;
            font-weight: 500;
          }
        }
      }
    }
  }

  // WhatsappBot Section Styles
  .whatsappSection {
    padding: 150px 0;

    @media screen and (max-width: 992px) {
      padding: 50px 0;
    }

    @media screen and (max-width: 767px) {
      background-color: white;
    }

    .holder {
      border-radius: 50px 0 0 50px;
      background: #EDEDED;
      padding: 60px 40px;
      display: flex;

      @media screen and (max-width: 767px) {
        background-color: white;
        padding: 0 30px;
      }

      > img {
        max-width: 350px;
        width: auto;
        margin: -220px 0;

        @media screen and (max-width: 940px) {
          width: 20%;
          margin-left: 50px;
        }

        @media screen and (max-width: 767px) {
          display: none;
        }
      }
    }

    .textWrap {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: flex-start;
      gap: 25px;
      align-self: stretch;
      color: #4D5156;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;

      /* 166.667% */
      .head {
        display: flex;
        flex-direction: column;
        gap: 5px;
        color: #828282;
        font-size: 25px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;

        @media screen and (max-width: 767px) {
          font-size: 22px;
        }

        h5 {
          color: #4D5156;
          font-size: 40px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.8px;
          text-transform: capitalize;
          margin: 0;

          @media screen and (max-width: 767px) {
            font-size: 30px;
          }
        }
      }

      p {
        margin: 0;
        @media screen and (max-width: 767px) {
          font-size: 16px;
        }
      }

      > a {
        display: flex;
        width: 293px;
        padding: 15px 20px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        border-radius: 10px;
        background: var(--Whatsapp-Color, #25D366);
        color: white;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;

        &:hover {
          background-color: darken($color: #25D366, $amount: 3fstatsHolder);
        }

        @media screen and (max-width: 767px) {
          font-size: 18px;
          padding: 12px 30px;
          width: 100%;
        }
      }
    }

  }

  // All Benefits Section Styles
  .allBenefits {
    padding: 80px 0;

    @media screen and (max-width: 992px) {
      padding: 50px 0;
    }
    @media screen and (max-width: 767px) {
      padding: 15px 0;
    }

    .contentHolder {
      background-color: #f0eff4;
      border: 1px solid #eceaf1;
      display: flex;
      flex-wrap: wrap;

      @media screen and (max-width: 767px) {
        flex-direction: column-reverse;
      }

      > .wrap {
        display: flex;
        flex: 1;
      }

      .listWrap {
        padding: 70px 40px;

        @media screen and (max-width: 992px) {
          padding: 40px 30px;
        }
        @media screen and (max-width: 767px) {
          padding: 30px 20px;
        }

        ul {
          padding: 0;
          margin: 0;
          list-style: none;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 25px;
          font-size: 16px;
          color: #656565;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;

          > li {

            padding-left: 40px;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 5px;

            &::before {
              position: absolute;
              content: '';
              top: 2px;
              left: 0;
              width: 20px;
              height: 20px;
              background: url(../../assets/icons/landingPage/bulletMark.svg) no-repeat;
              background-size: contain;
            }

            h5 {
              color: #333;
              font-size: 18px;
              font-style: normal;
              font-weight: 600;
              margin-bottom: 0;
              line-height: 25px;
            }

            p {
              margin: 0;
            }
          }
        }
      }

      .textArea {
        background: url(../../assets/images/benefitsBg.png) no-repeat;
        background-size: cover;
        padding: 40px 40px 80px;
        display: flex;
        align-items: end;
        color: white;
        text-transform: capitalize;

        @media screen and (max-width: 767px) {
          padding: 30px;
        }

        > .holder {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 50px;

          @media screen and (max-width: 992px) {
            gap: 30px;
          }

          @media screen and (max-width: 767px) {
            gap: 30px;
          }
        }
      }

      h6 {
        font-size: 60px;
        font-style: normal;
        font-weight: 800;
        line-height: 81px;

        @media screen and (max-width: 992px) {
          font-size: 40px;
          line-height: 55px;
        }

        @media screen and (max-width: 767px) {
          font-size: 30px;
          line-height: 45px;
        }

        span {
          color: #49E4C8;
        }
      }

      .link {
        display: flex;
        align-items: center;
        gap:10px;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 150% */
        text-decoration-line: underline !important;

        @media screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 18px;
        }
        cursor: pointer;
      }
    }
  }

  .contactUs {
    padding: 80px 0;
    background: linear-gradient(to bottom, #6C5CE71A 50%, white 50%);

    @media screen and (max-width: 767px) {
      padding: 5px 0;
    }

    .contact-container {
      width: 100%;
      max-width: 1200px;
      margin: 50px auto;

      @media screen and (max-width: 767px) {
        font-size: 25px;
        margin: auto;
      }

      .contact-header {
        padding: 20px;
        border-radius: 8px 8px 0 0;
        text-align: center;
    
        h2 {
          font-size: 36px;
          font-weight: bold;
          color: #333333;
          @media screen and (max-width: 767px) {
            font-size: 25px;
          }
        }
    
        p {
          font-size: 18px;
          color: #666;
          margin-top: 8px;
          line-height: 24px;
          font-weight: 500;

          @media screen and (max-width: 767px) {
            font-size: 12px;
          }
        }
      }
    
      .contact-content {
        display: flex;
        flex-wrap: wrap;
        background: #ffffff;
        border-radius: 12px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        padding: 20px;

        @media screen and (max-width: 767px) {
          flex-direction: column-reverse;
          padding: 0px;
        }
    
        .contact-info {
          flex: 1;
          padding: 30px;
          font-size: 14px;
          background-color: #F5F4F8;
          color: #4D5156;
          gap: 10px;
    
          div {
            font-size: 14px;
            margin: 10px 0;
            margin-bottom: 20px;
            display: flex;
            gap: 10px;

            .locationImg {
              height: 17px;
              margin-top: 5px;
            }
          }
        }
    
        .contact-form {
          flex: 2;
          padding: 20px;
          display: flex;
          flex-direction: column;

          @media screen and (max-width: 767px) {
            flex: 1;
          }

          .field {
            margin-bottom: 15px;

            .text-danger {
              font-size: 12px;
            }
          }

          .captcha.field {
            margin: 0;
          }

          .phone.field {
            flex: 1 1;
            margin-bottom: 0;
          }
    
          input,
          textarea {
            width: 100%;
            padding-right: 10 30;
            font-size: 14px;
            border: 1px solid lightGray;
            border-radius: 4px;
            outline: none;
          }
    
          textarea {
            resize: none;
            height: 200px;
          }
    
          .form-row {
            display: flex;
            gap: 10px;
            margin-bottom: 15px;
    
            input {
              flex: 1;
            }
          }

          .disclaimer {
            font-size: 9px;
            color: gray;

            a {
              color: #6C5CE7;
            }
          }

          button {
            width: 170px;
            height: 50px;
            border-radius: 10px;
          }
        }
      }
    }
  }
  

  // Footer for the Landing Page
  footer {
    border-top: 1px solid #C3C3C3;
    background-color: white;
    padding: 50px 0;
    @media screen and (max-width: 767px) {
      padding: 30px 0;
    }

    .holder {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      gap: 20px;
      @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: center;
      }

      .copyRight {
        display: flex;
        flex: 1;
        flex-wrap: nowrap;
      }

      .links {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 20px;
        margin: 0;
        padding: 0;
        list-style: none;

        a {
          color: #484848;
          cursor: pointer;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-decoration-line: underline !important;
          text-transform: capitalize;
        }
      }
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
  margin: 0;
}
