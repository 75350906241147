@import "src/scss/_colors.scss";

@keyframes changeColor {
  0% {
    color: $red;
  }

  50% {
    color: lighten($color: $red, $amount: 15%);
  }

  100% {
    color: $red;
  }
}

.orderingView {

  @media screen and (max-width: 900px) {
    // background-color: white;
  }

  .text-danger.items {
    padding-left: 15px;
    padding-bottom: 10px;
  }

  .successWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .content {
      display: flex;
      text-align: center;
      flex-direction: column;
      // top: 50%;
      justify-content: center;
      align-items: center;
      // width: 50%;
      background-color: white;
      margin-top: 80px;
      padding: 60px;
      row-gap: 20px;
      border-radius: 20px;

      @media screen and (max-width: 900px) {
        margin-top: 80px;

      }

      .header {
        font-weight: 600;
        font-size: 28px;
        line-height: 42px;
        color: #4D5156;
      }

      .subheading {
        font-size: 16px;
        color: #828282;
      }

      .text {
        // width: 600px;
      }
    }

    .text {
      display: block;
      width: 60%;

      .whatsapp {
        color: $whatsApp; 
        font-weight: 600;
      }

      .icon {
        width: 25px;
        margin-bottom: 4px; 
        margin-right: 2px;
      }
    }
  }
}


//  Place order View Styles
.placeOrderView {
  @media screen and (max-width: 767px) { padding: 22px 0;}

  h1 {
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 15px;
    @media screen and (max-width: 767px) { font-size: 22px;}

  }

  .sectionsWrap {
    display: flex;
    flex: 1;
    flex-direction: column;
    row-gap: 30px;

    .btn-container {
      margin-bottom: 30px;
    } 

    .btn {
      display: flex;
      width: 250px;
      height: 60px;
      padding: 12px 30px;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      @media screen and (max-width: 767px) { width: 100%;}
    }
  }

  .contentWrap {
    display: flex;
    gap: 30px;

    @media screen and (max-width: 992px) {
      flex-direction: column-reverse;
    }
  }

  .contentSection {
    padding: 30px;
    border-radius: 8px;
    border: 1px solid lighten($color: $inputBorder, $amount: 5);
    background: white;
    @media screen and (max-width: 992px) { padding: 20px;}
    @media screen and (max-width: 767px) { padding: 15px; margin: 0 -10px;}

    h2 {
      text-transform: capitalize;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 0;
      @media screen and (max-width: 767px) { font-size: 16px;}
    }

    .head {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      margin-bottom: 20px;

      p {
        color: $darkGray;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
      }
    }

    .form-check {
      padding-left: 0px;
    }

    .audioContainer {
      cursor: pointer;
      display: flex;
      flex-direction: row;

      .loader {
        display: flex;
        align-items: center;
        margin-right: 0;
        margin-left: auto;
        color: $darkGray;
        font-size: 15px;

        @media screen and (max-width: 500px) {
          flex-direction: column;
          font-size: 13px;
          padding: 5px;
        }

        .text {
          margin-right: 8px;
        }
      }

      .btn-AddVoice {
        color: $primary;
        display: flex;
        height: 60px;
        padding: 12px 20px;
        justify-content: start;
        line-height: normal;
        align-items: center;
        gap: 8px;
        border-radius: 0 0 8px 8px;
        border: 1px solid #DEE2E6;
        background: rgba(108, 92, 231, 0.05);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        width: 200px;
        text-transform: capitalize;
        margin-top: -1px;

        &.disabled {
          pointer-events: none;
          background-color: lighten($color: $darkGray, $amount: 35);
          cursor:not-allowed !important;
          color: gray;
         
          .icon {
            color: grey;
          }
        }
        
        @media screen and (max-width: 767px) { height: 50px;}

        .audio-recorder {
          box-shadow: none;
          background-color: transparent;
          width: 0;
        }

        .icon {
          font-size: 20px;
          line-height: normal;
          color: $red;
          
        }

        &:hover {
          background: rgba(108, 92, 231, 0.09);
        }

        &.recording {
          .icon {
            animation: changeColor 1.2s infinite;
          }
        }
      }
    }
  }

  .optionWrap{
    display: flex;
    column-gap: 20px;
    margin-top: 30px;

    input[type="radio"] {
      display: none;
    }

    label {
      display: flex;
      padding: 25px 30px;
      justify-content: center;
      align-items: center;
      gap: 15px;
      border-radius: 8px;
      border: 1px solid var(--Gray-lighter, #E8E8E8);
      background: #FFF;
      padding-left: 67px;
      cursor: pointer;
      font-size: 18px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      position: relative;

      @media screen and (max-width: 767px) {
        padding: 15px 24px 15px 44px;
        font-size: 16px;
      }

    }

    .form-check {
      &.disabled {
        background-color: lighten($color: $darkGray, $amount: 30);
        :active{
          pointer-events: none;
        }
        cursor: not-allowed;
        // color: $darkGray;
        opacity: 0.3;
      }
    }


    label::before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 30px;
      width: 22px;
      height: 22px;
      background-color: transparent;
      border: 2px solid $darkGray;
      border-radius: 50%;
      @media screen and (max-width: 767px) {
        left: 10px;
      }
    }

    label::after {
      content: "";
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 34px;
      transform: translateY(-50%);
      width: 14px;
      height: 14px;
      background-color: $primary;
      border-radius: 50%;
      display: none;

      @media screen and (max-width: 767px) {
        left: 14px;
      }
    }

    input[type="radio"]:checked + label {
      background-color: rgba(108, 92, 231, 0.05);
    }

    input[type="radio"]:checked + label::before {
      border-color: $primary !important;
      // background-color: $primary !important;
    }

    input[type="radio"]:checked + label::after {
      display: block;
    }
  }

  // metaData Styles
  .metaWrap {
    width: 230px;

    @media screen and (max-width: 992px) {
      width: 100%;
    }

    .holder {
      display: flex;
      flex-direction: column;
      row-gap: 30px;
      align-items: center;
      @media screen and (max-width: 992px) {
        flex-direction: row;
        align-items: end;
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: end;
        row-gap: 15px;
      }

    }

    .shopHead {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 10px;
      width: 100%;
      @media screen and (max-width: 992px) {
        flex-direction: row;
        column-gap: 15px;
        flex: 1;
      }

      .shopImgWrap {
        width: 100%;
        border-radius: 8px;
        border: 4px solid #FFF;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 992px) {
          width: 100px;
          height: auto;
          aspect-ratio: 1/1;
        }
        @media screen and (max-width: 992px) { width: 80px;}
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          &.default{
            width: 80%;
            height: auto;
            padding: 60px;
            @media screen and (max-width: 992px) {padding: 10px;}
            @media screen and (max-width: 767px) {width: 60%;}
          }
        }
      }

      .shopContent {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        row-gap: 2px;
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @media screen and (max-width: 992px) {
          text-align: left;
          justify-content: start;
          align-items: start;
          .name{
            font-size: 20px;
          }
        }

        .shop {
          font-weight: 500;
        }

        .area {
          color: $darkGray;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    .rating {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      column-gap: 5px;
      line-height: normal;
    }

    .menuLink {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 15px;
      flex-direction: column;
      row-gap: 10px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: $primary;
      border-radius: 8px;
      border: 1px dashed $primary;
      background: rgba(108, 92, 231, 0.04);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
      cursor: pointer;

      @media screen and (max-width: 992px) {
        width: auto;
        padding: 10px;
        img{display: none;}
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        padding: 4px;
        border: none;
      }

      > span {
        span {
          font-weight: 400;
          font-size: 12px;
        }
      }

      &:hover {
        background: rgba(108, 92, 231, 0.07);
      }

      img {
        width: 22px;
        height: auto;
      }
    }
  }

  // 
  .orderWrap {
    > .holder {
      position: relative;
      border-radius: 8px;
      border-bottom-left-radius: 0;
      border: 1px solid $inputBorder;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);

      .form-select {
        appearance: auto;
        font-weight: 0;
        height: 60px;
        color: lighten($color: $text-color, $amount: 6);
      }
    }
    .transWrap{
      display: block;
      direction: rtl;
      font-size: 14px;
      color: #888;
      padding: 10px 15px;
      position: relative;
    }
  }

  .voiceNoteHolder {
    margin: 12px 15px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    flex-direction: row;
    #audio-play{
      height: 40px;
    }

    .retry{
      cursor: pointer;
      margin-top: 6px;
    }

    > a {
      margin-left: 10px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    > span {
      display: block;
      height: 30px;
      width: 300px;
      background-color: #e2e2e2;
    }

    .audioRecorder {
      display: flex;
      flex-direction: column;
    }
  }

  textarea {
    &.form-control {
      // border: none;
      min-height: 220px;
      width: calc(100% - 8px);
      margin: 4px auto;
      border-radius: 0;
    }
  }

  .itemsDropdown {
    .DropdownButton {
      justify-content: space-between;
      background: rgba(108, 92, 231, 0.05);
      margin-top: -1px;
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      font-size: 14px;
      font-weight: 500;

      &::after {
        font-size: 20px;
      }

      span {
        > span {
          color: $darkGray;
          font-weight: 400;
        }
      }
    }

    .dropdown-menu {
      width: 100%;
      max-height: 258px;
      overflow-y: auto;
    }
  }

  .AddressWrap {
    margin-top: 30px;

    h3 {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .btnAddAddress {
      display: inline-flex;
      align-items: center;
      column-gap: 10px;
      cursor: pointer;

      .icon {
        font-size: 20px;
      }
    }

  }

 
}

.tooltip-inner {
  background-color: $lightGrey !important;
  color: $text-color !important;
  font-size: 12px;
  font-weight: 500;
}

.tooltip-arrow::before{
  border-top-color: $lightGrey !important;
}
