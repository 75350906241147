@import "src/scss/colors";

.dropdown-toggle.btn {
  border-radius: 8px;
  min-height: 50px;
  background: #FFF;
  color: $text-color;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border: 1px solid $inputBorder;
  display: flex;
  align-items: center;
  min-width: auto;
  column-gap: 8px;
  padding: 8px 15px;
  width: 100%;
  letter-spacing: normal;

  &:hover,
  &:focus,
  &:focus-within,
  &:active,
  &:visited,
  &:focus-visible {
    border: 1px solid $inputBorder !important;
  }
}

.dropdown-menu {
  border-radius: 8px;

  .dropdown-item {
    color: $text-color;

    &:active {
      background-color: rgba($color: $primary, $alpha: 0.2%);
    }
  }

}

.btn {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  padding: 12px 30px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  min-width: auto;

  &.large {
    padding: 24px 32px;
    font-size: 20px;
    border-radius: 61px;

  }

  &.white {
    background-color: white;
    color: $primary;
  }

  &.borderPrimary {
    border: 1px solid $primary;
    color: $primary;

    &:hover,
    &:active,
    &:focus {
      background-color: $primary;
      border-width: 1px;
      color: white;
    }
  }
}

.btn:active,
.btn:focus,
.btn:first-child:active,
.btn:last-child:active {
  // border: none;
}

// Primary
.btn-primary {
  background-color: $primary !important;
  border-color: $primary !important;
  color: white !important;

  &:disabled {
    background-color: gray !important;
    border-color: gray !important;
  }
}

.btn-primary:hover {
  background-color: darken($color: $primary, $amount: 5);
}

// Header SignIn/SignUp buttons

.ssButton {
  padding: 4px 20px;
  background-color: transparent;
  font-size: 14px;
  line-height: 21px;
  min-height: 29px;
  font-weight: 600;
  text-align: center;
  border: solid 1.5px;
  border-color: $primary;
  color: $primary;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  border-radius: 32px;

  &.fill {
    background-color: $primary;
    color: white;

    &:hover {
      background-color: darken($color: $primary, $amount: 5);
      color: white;
    }

  }

  &:hover {
    border-color: darken($color: $primary, $amount: 5);
    color: darken($color: $primary, $amount: 5);
  }
}

.btnWhatsApp {
  display: flex;
  height: 50px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  background-color: $whatsApp !important;
  color: $white !important;
  // font-size: 16px;
  // font-weight: 600;
  // line-height: normal;
  // text-transform: capitalize;

  &:hover,
  &:focus,
  &:focus-within,
  &:active,
  &:visited,
  &:focus-visible {
    background-color: darken($color: $whatsApp, $amount: 3) !important;
    color: white !important;
  }

  &::before {
    width: 21px;
    height: 20px;
    content: '';
    //background: url(../images/buttonIcons/ico-whatsapp-white.svg) no-repeat 100%;
  }
}

.btnShare {
  background: white;
  border: 1px solid #828282;
  color: $text-color;

  &:hover,
  &:focus,
  &:focus-within,
  &:active,
  &:visited,
  &:focus-visible {
    background-color: darken($color: white, $amount: 3) !important;
    color: $text-color !important;
    border: 1px solid #828282 !important;
  }

  &::before {
    width: 21px;
    height: 20px;
    content: '';
    //background: url(../images/buttonIcons/ico-share-gray.svg) no-repeat 100%;
  }

}

