@import "src/scss/_colors.scss";

.alert-danger {
    width: 100%;
    padding: 5px;
    margin-top: 10px;
}

.addAddress {
    cursor: pointer;
    color: $primary;
}

.error {
    color: red;
}

.currentAddr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 5px;

    .label {
        font-weight: 600;
    }

    .title {
        color: grey;
    }
}

.placeholderContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .outer {
        width: 80%;
    }

    .inner {
        width: 100%;
        height: 20px;
        border-radius: 6px;
        color: lightgrey;
    }
}
