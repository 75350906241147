// Custom Variables
$primary : #6C5CE7;
$primarySweet : #F2F2F7;
$text-color: #42464a;
$bodyBg: #F8F8F8;
// $bodyBg: #F1F0F0;
$green: #27bd5f;
$red: #e84b4b;
$inputBorder: #dee2e6;
$discount-color: #A29CCE;
$off-color: #FFB388;

$whatsApp: #25D366;

$priceColor: #D91919;
$darkGray: #828282;

$formBorder: #D0D1D3;
$placeholder-color: #e3e3e3;
$formBorder: #D0D1D3;

$white: #ffffff;

$lightGrey:  #E8E8E8;

$popover: #2F2D2D;

$arrowBg: #333;

$breadcrumb-light: #8a8a8a;
$breadcrumb-dark: #484848;
