@import "src/scss/_mixins.scss", "src/scss/_colors.scss";

.pagination {
  margin: 10px;

  .active {
    .page-link {
      color: $primarySweet !important;
      background-color: $primary !important;
    }
  }

  .page-link {
    color: $primary !important;
  }
}