.veirficationContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 80vh;

    .successContainer {
        padding: 20px;
        gap: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .checkBox {
            width: 100px;
            height: 100px;
            background-color: #6C5CE7;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .text {
            color: "#6C5CE7"
        }
    }
}