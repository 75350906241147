//  Modal Styles
@import "src/scss/colors";

.modal {
  text-align: left;
  color: $text-color;
  @media screen and (max-width: 767px) { padding-left: 0 !important;}

  .modal-dialog {
    min-width: 700px;

    @media screen and (max-width: 992px) {
      max-width: 90%;
      min-width: auto;
      margin: 0 auto;

    }

    @media screen and (max-width: 767px) {
      max-width: 100%;
      transform: translateY(-50%) !important;
      top: 50%;
      height: 100%;
    }
  }

  .modal-content {
    color: $text-color;
    padding: 40px;
    position: relative;

    @media screen and (max-width: 767px) {
      // height: 100vh !important;
      border-radius: 0;
      overflow: auto;
    }
  }

  .btn-close {
    position: absolute;
    right: 30px;
    top: 30px;
  }

  .modal-header,
  .modal-body,
  .modal-footer {
    padding: 0;
    text-align: left;
    align-items: start;
    border: 0;
  }

  .modal-title {
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 5px;
    color: $text-color;

    .whatsApp {
      color: $whatsApp;
    }
  }

  .modal-header {
    flex-direction: column;
    margin-bottom: 15px;

    .mBackTop{ 
      margin-top: -8px;
      margin-bottom: 10px;
      cursor: pointer;

      img {
        width: 20px;
        height: 20px;
      }
    }

    h2 {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 0;
    }
  }

  .modal-body {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-bottom: 24px;

    p {
      margin: 0;
    }

    .grayText {
      color: #828282;
    }

    .address {
      font-weight: 600;
    }

    .deleteText {
      font-size: 16px;
      margin-bottom: 30px;
    }

  }

  // Modal Footer
  .modal-footer {
    justify-content: start;
    align-items: center;
    column-gap: 25px;
    width: 100%;

    button {
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      // text-transform: capitalize;
      padding: 12px 26px;
      margin: 0;

      &.btn-primary {
        min-width: 170px;
      }

      &.btnWhatsApp {
        min-width: 180px;

        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }
    .btn{
      &.btn-cancel{
        // color: $darkGray;
        // font-weight: 400;
        padding: 0;
        min-width: auto;
      }
    }
  }

  .infoBox {
    background: rgba(108, 92, 231, 0.07);
    display: flex;
    width: 100%;
    padding: 10px 15px;
    align-items: center;
    gap: 10px;
    color: $primary;
    margin-top: -5px;
  }

  &.dAddressModal {
    .modal-content {
      padding: 30px;

      .modal-header {
        p {
          strong {
            font-weight: 500;
          }

          margin-bottom: 0;
        }
      }

      .modal-body {
        margin-bottom: 24px;
      }

      .modal-body.no-margin {
        margin-bottom: 0px;
      }

      .mapWrap {
        border-radius: 8px;
        overflow: hidden;
        width: 100%;

        .placeholder {
          width: 618px;
          height: 450px;
          color: $formBorder;
        }

        img {
          width: 100%;
          height: auto;
        }
      }

      .modal-body {
        row-gap: 24px;
      }
    }

    .PreviewWrap {
      border-radius: 8px;
      display: flex;
      padding: 15px;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;

      h4 {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0;
      }

      .pBody {
        width: 100%;

        @media screen and (max-width: 660px) {
          display: grid;
          gap: 10px;
        }

        .addrRow {
          display: flex;
          justify-content: space-between;

          >span {
            margin-top: 8px;
            margin-bottom: 5px;
          }

          @media screen and (max-width: 660px) {
            display: grid;
            gap: 10px;
          }
          
        }

        span {
          flex: 1;
          padding-right: 30px;
        }
      }

      .btnEdit {
        display: flex;
        column-gap: 10px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $primary;
        padding: 5px 12px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 1px solid rgba(108, 92, 231, 0.50);
        background: white;
        cursor: pointer;

        .icon {
          font-size: 16px;
        }
      }
    }

    .PreviewWrap.bgColor {
      background: rgba(108, 92, 231, 0.10);
    }

    .PreviewWrap.guest {
      padding: 0px;
    }

    .editAddWrap {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 24px;

    }
  }
}

.locationModal {
  .locationText {
    font-size: 15px;
    margin-bottom: 20px;
  }
}

#menuModal {
  .modal-dialog {
    width: 80%;
    max-width: 1172px;

    @media screen and (max-width: 992px) {
      width: 100%;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .modal-header {
    h1 {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 0;
    }
  }

  .modal-content {
    padding: 30px;

    @media screen and (max-width: 767px) {
      padding: 15px;
    }
  }

  .modal-body {
    flex-direction: row;
    margin-bottom: 0;

  }
}

.loginModal {

  input[type="tel"] {
    cursor: text;
  }

  .modal-header {
    margin-bottom: 24px;

    .modal-title {
      margin-bottom: 0px;
    }

    .smallTitle {
      color: $darkGray;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      display: flex;
      column-gap: 15px;
      margin-bottom: 15px;

      .waColor {
        color: $whatsApp;
      }
    }

    p {
      margin-bottom: 0;
      font-weight: 300;
      line-height: normal;
    }
  }

  .modal-body {
    .form-label {
      margin-bottom: 5px;
      font-size: 14px;
      font-weight: 500;
      line-height: normal;

      .otpTimer {
        color: $darkGray;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .input-group {
      width: 100%;

      .input-group-text {
        color: $text-color;
      }
    }
  }

  .formFields {
    width: 100%;
  }

  .getOTPWrap {
    width: 100%;
    min-width: 320px;
    max-width: 386px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;

    .btn {
      width: 100%;
    }
  }

  .QrCodeWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 5px;
    font-size: 12px;
    color: $text-color;

    img {
      max-width: 140px;
      height: auto;
    }
  }

  .or-divider {
    color: $darkGray;
    height: 1px;
    position: relative;
    width: 100%;
    margin: 15px 0;
    background-color: #C3C3C3;

    &::after {
      background-color: white;
      padding: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      content: "OR";
      transform: translate(-50%, -50%);
      font-size: 12px;
    }
  }
}