@import "src/scss/_mixins.scss", "src/scss/_colors.scss";

.vendors {

  // all vendors styles
  .sortWrap {
    margin: 20px 0 5px;
    // margin-bottom: -40px;
  }

  .sorting.nearest {
    margin-bottom: 30px !important
  }

  .sorting {
    position: relative;
    justify-content: right;
    align-items: center;
    column-gap: 10px;

    margin-right: auto;
    margin-left: auto;
    display: flex;
    margin-bottom: -30px;

    width: 100%;

    .addrContainer {
      justify-content: end;

      .userAddr {
        font-size: 13px;
        color: #6C5CE7;
        cursor: pointer;
        display: flex;
        gap: 8px;
  
        .edit {
          margin-bottom: 6px;
          margin-left: 5px;
        }
      }
      @media screen and (max-width: 1000px) {
        width: 100%;
        margin-bottom: 20px;
      }
      @media screen and (max-width: 767px) {
        margin-bottom: 0;
      }
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 0;
      display: flex;
      flex-direction: column-reverse;
      align-items:flex-end;
      gap: 10px;
    }

    >div {
      display: flex;
      column-gap: 10px;

      .dropdown-toggle.location {
        min-width: auto !important;
        &::after{
          display: none;
        }
      }

      &:first-child {
        &::before {
          display: none;
        }
      }

      // Divider
      // &::before{
      //   display: block;
      //   content: '';
      //   border-left: 1px solid $lightGrey;
      //   column-gap: 10px;
      // }
    }

    .sortingDropdown {
      background-color: white !important;
      color: $text-color !important;
      font-weight: normal !important;
      border-color: $lightGrey !important;
      min-width: 240px;
      height: 40px;
      min-height: auto;
      font-size: 14px;

      strong {
        font-weight: 500;
      }

      &:hover {
        background-color: white !important;
      }
    }

    .dropdown {
      button {
        justify-content: space-between;
      }
    }
  }

  

  .vendorsWrap {
    form {
      .filterBtn {
        >a {
          color: $text-color;
        }
      }
    }

    .selectArealabel {
      font-size: 16px;
      font-weight: 500;
      margin-right: 10px;
      text-transform: capitalize;
    }

    .areaSelection {
      margin-bottom: 24px;

      .btn {
        padding: 8px 20px;
      }
    }

    .clear-icon {
      position: absolute;
      top: 50%;
      right: 25px;
      transform: translateY(-50%);
      cursor: pointer;
    }

    .filterWrap {
      margin-bottom: 10px;
      // .form-control{
      //   @media screen and (max-width: 767px) { height: 40px !important;}
      // }

      @media screen and (max-width: 767px) {
        margin-bottom: 5px;

        #searchForm {
          row-gap: 10px !important;
          flex-direction: column;
        }
      }

      .searchfield {
        // width: 100%;
        position: relative !important;

        .dropdown-item.active {
          background-color: lightgray;
          color: black;
        }

        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }


    }
  }

  .toggleSwitch {
    display: flex;
    align-items: baseline;
    background-color: white;
    border: 1px solid #E8E8E8;
    overflow: hidden;
    border-radius: 8px;
    cursor: pointer;
    column-gap: 0 !important;

    @media screen and (max-width: 992px) {
      display: none !important;
    }

    >a {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      color: lighten($color: $darkGray, $amount: 15);

      &:hover {
        background-color: lighten($color: $primary, $amount: 50);
      }

      &.active {
        background-color: $primary;
        color: white;
      }

      svg {
        width: 20px;
        height: auto;
      }
    }
  }

  .selectAreaDropDown {
    width: 270px;

    @media screen and (max-width: 992px) {
      width: 220px;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    .dropdown {
      width: 230px;

      @media screen and (max-width: 800px) {
        width: 100%;
      }

      button {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover,
        &:focus {
          background: white !important;
          opacity: 1;
          color: unset !important;
        }
      }
    }


    .dropdown-toggle {
      justify-content: space-between;
      // @media screen and (max-width: 767px) { min-height: 40px;}

      >span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &.show {
        color: $text-color;
        background-color: white;
        border-color: white;
      }

      span>span {
        font-size: 14px;
        color: #828282;
      }
    }

    .dropdown-menu {
      border-radius: 10px;
      min-width: 100%;
      max-height: 450px;
      overflow: auto;

      scrollbar-width: none;
      -moz-scrollbar-wodth: none;

      .dropdown-item {
        padding: 5px 10px !important;
      }

      .dropdown-item .link {
        width: 100%;
        padding: 0;
      }

      .active {
        background-color: $primarySweet;
        border-radius: 8px;
        color: $text-color;
      }

    }
  }

  .vendorHead {
    padding-top: 30px;
    position: sticky;
    top: 0;
    z-index: 1;
    // background-color: $bodyBg;
    transition: all 0.3s ease;
    background: rgb(135, 122, 232);
    background: linear-gradient(0deg, rgba(135, 122, 232, 1) 0%, rgba(172, 142, 212, 1) 69%);

    @media screen and (max-width: 767px) {
      // margin-top: 15px;
      padding-top: 15px;
    }

    &.active {
      // background-color: white;
      padding-top: 15px;
      border-bottom: 1px solid #EDEDED;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.30);

      @media screen and (max-width: 992px) {
        background-color: $bodyBg;
      }
    }
  }

  // Category Navigation styles
  .catNav {
    position: -webkit-sticky;
    position: sticky !important;
    top: 0 !important;
    z-index: 9;
    // background-color: $bodyBg;
    transition: all 0.3s ease;

    .slider-control-bottomcenter {
      display: none;
    }

    &.active {
      // background-color: white;
      color: white;
      background: linear-gradient(180deg, #9C75CD -70%, #6C5CE7 100%);
      // background: $primary;
      border-bottom: 1px solid #EDEDED;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);

      ul>li {
        a {
          background-color: transparent;
          border-color: white;
          color: white;

          &:hover {
            background-color: rgba($color: #fff, $alpha: 0.18%);
            color: white;
          }

          &.active {
            background-color: white;

            &:hover,
            &:visited {
              background-color: white;
              color: $primary;
            }
          }
        }
      }
    }

    .slick-track {
      padding: 13px 0;
      margin: 0;
      list-style: none;
      column-gap: 8px;
      // overflow-x: auto;
      display: flex;
      flex-direction: row;
      // @media screen and (max-width: 768px) {
      //   &::-webkit-scrollbar{
      //     display: none;
      //   }
      // }
    }

    .slick-slider {
      display: flex;
      flex-direction: row;
      overflow: hidden;
      align-items: center;

    }

    .slick-list {
      overflow: hidden;
      width: 100%;
    }

    .slick-slide {
      display: inline-block;
      min-width: fit-content;

      >a,
      span {
        display: block;
        padding: 6px 15px;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        // border: 1px solid var(--lightGray, #C3C3C3);
        border: 1px solid var(--lightGray, #ffffff);
        // color: $text-color;
        color: white;
        // background: #FFF;
        background: transparent;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        cursor: pointer;
        transition: all 0.1s ease;

        &:not([href]):not([class]) {
          &:hover {
            background: rgba(108, 92, 231, 0.10);
            background-color: white;
            color: $primary;
            border-color: $primary;
          }
        }

        &:hover {
          background-color: rgba($color: #fff, $alpha: 0.15);
        }

        &.active {
          color: $text-color;
          background-color: white;
          border-color: white;

          &:hover {
            background-color: white;
            color: $text-color;
            border-color: white;
          }
        }
      }
    }

    .slick-arrow {
      width: 45px;
      aspect-ratio: 1/1;
      // background-color: rgba($color: #000000, $alpha: 0.15);
      background-color: transparent;
      border-radius: 100px;
      display: flex !important;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 2;

      >img {
        width: 50%;
      }

      &:hover {
        background-color: rgba($color: #000000, $alpha: 0.08);
      }

      &::after {

        position: absolute;
        display: block;
        content: '';
        width: 30px;
        height: 100%;
        z-index: -1;
        background: rgb(248, 248, 248);
        background: linear-gradient(90deg, rgba(248, 248, 248, 0) 0%, rgba(248, 248, 248, 1) 80%);
        right: 32px;

        display: none;
      }
    }
  }

  .container.vendors {
    margin-bottom: 40px;
  }

  .allCatWrap {
    // margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 40px;

    @media screen and (max-width: 767px) {
      margin-top: 10px;
    }

    >div {
      >div {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
      }
    }

    .noResults {
      margin-top: 40px;
    }

    .spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 20px;
    }

    .catWrapper {
      h2 {
        color: $text-color;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        padding: 5px;
        padding-bottom: 0;

        .count {
          color: rgb(123, 122, 122);
          font-size: 16px;
        }
      }
    }

    .catlistWrap {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      list-style: none;
      row-gap: 15px;
      padding: 0 2px;

      .vendorCard {
        position: relative;
        border-radius: 8px;
        background: #FFF;
        padding: 20px;
        display: flex;
        column-gap: 20px;
        align-items: flex-start;
        border: 1px solid #fff;
        -webkit-box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.03);
        box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.03);
        cursor: pointer;

        @media screen and (max-width: 767px) {
          padding: 10px;
          column-gap: 10px;
        }

        &:hover {
          background: rgba(108, 92, 231, 0.07);
          border-color: lighten($color: $primary, $amount: 32%);
        }

        .imgWrap {
          overflow: hidden;
          position: relative;
          width: 163px;
          aspect-ratio: 1 / 1;
          border-radius: 8px;
          background: radial-gradient(66.25% 66.25% at 55.94% 100%, rgba(189, 189, 189, 0.52) 0%, rgba(137, 135, 135, 0.10) 100%);

          @media screen and (max-width: 767px) {
            width: 70px;
          }

          img {
            width: 100%;
            object-fit: cover;
            height: -webkit-fill-available;
            height: 100%;

            &.default {
              width: 40px;
              height: auto;
              position: relative;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);

              @media screen and (max-width: 767px) {
                width: 20px;
              }

              @media screen and (-webkit-min-device-pixel-ratio: 0) {

                _::-webkit-full-page-media,
                _:future,
                :root &.default {
                  transform: translate(-50%, 150%);

                  @media screen and (max-width: 767px) {
                    transform: translate(-50%, 100%);
                  }
                }
              }
            }
          }
        }

        .textWrap {
          display: flex;
          flex-direction: column;
          column-gap: 10px;

          @media screen and (max-width: 992px) {
            flex: 1;
          }

          h3 {
            color: $primary;
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            cursor: pointer;

            @media screen and (max-width: 767px) {
              font-size: 18px;
              font-weight: 500;
              margin-bottom: 0;
            }
          }

          >ul {
            padding: 0;
            margin: 0;
            list-style: none;
            display: flex;
            flex-direction: column;
            row-gap: 10px;

            li {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              display: flex;
              column-gap: 8px;

              .chevron {
                cursor: pointer;
                width: 13px;
                height: 21px;
              }
            }
          }
        }
      }
    }

    @media screen and (min-width: 992px) {
      &.gridView {
        .catlistWrap {
          flex-direction: row;
          flex-flow: wrap;
          gap: 15px;

          .vendorCard {
            width: 49.1%;

            .imgWrap {
              width: 90px;
            }
          }
        }
      }
    }


  }

  .offerTag {
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    display: flex;
    padding: 7px 27px;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    border-radius: 0px 10px 0px 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: inherit;
    background-color: $inputBorder;
    z-index: 1;

    &.discount {
      background: $discount-color;
      color: white;
    }

    &.off {
      background: $off-color;
      color: white;
    }
  }

  // Delivery Service Tag
  .dService {
    border-radius: 20px;
    border: 1px solid var(--lightGray, #C3C3C3);
    display: flex;
    padding: 4px 10px;
    justify-content: center;
    align-items: center;
    column-gap: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    background-color: rgba($color: #ffffff, $alpha: 0.5);
    // @media screen and (max-width: 767px) { width: max-content;}

    .freeTag {
      display: inline-block;
      font-weight: 600;
    }
  }
}

.popover {
  padding: 20px;
}

.ocTime {
  .open {
    color: $green;
  }

  .close {
    color: $red;
  }
}
