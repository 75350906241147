@import "src/scss/_colors.scss";

.addressList {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid $inputBorder;

    .addressControls {
        visibility: hidden;
        display: flex;
        column-gap: 12px;
        margin-left: 30px;
        margin-right: 15px;

        >.icon {
            &:hover {
                cursor: pointer;
                color: $primary;
            }
        }
    }

    .form-check-input {
        display: none;
    }

    >li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 10px;
        padding: 4px 0;

        &:hover {
            background-color: #f4f4f45d;

            .addressControls {
                display: flex;
                visibility: visible;

            }
        }


        label {
            background-color: transparent;
            padding: 0;
            border: 0;
            font-size: 16px;
            padding-left: 34px;

            &::before {
                left: 0;
                border-width: 1px;
                width: 20px;
                height: 20px;
            }

            &::after {
                left: 4px;
                width: 12px;
                height: 12px;
            }
        }
    }

    input[type="radio"]:checked+label::before {
        border-color: $primary !important;
    }

    input[type="radio"]:checked+label::after {
        display: block;
    }

    label::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 30px;
        width: 22px;
        height: 22px;
        background-color: transparent;
        border: 2px solid $darkGray;
        border-radius: 50%;

        @media screen and (max-width: 767px) {
            left: 10px;
        }
    }

    label::after {
        content: "";
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 34px;
        transform: translateY(-50%);
        width: 14px;
        height: 14px;
        background-color: $primary;
        border-radius: 50%;
        display: none;

        @media screen and (max-width: 767px) {
            left: 14px;
        }
    }
}
