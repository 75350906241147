@import "src/scss/imports";

.giveFeedbackBtn {
  position: fixed;
  content: '';
  top: 50%;
  right: -62px;
  display: block;
  transform: rotate(-90deg);
  z-index: 1;
  padding: 10px 15px;
  align-items: flex-start;
  gap: 10px;
  color: white;
  border-radius: 10px 10px 0px 0px;
  background: rgba(108, 92, 231, 0.50);
  box-shadow: -3px 4px 6px 0px rgba(0, 0, 0, 0.20);
  transition: all 0.3s ease;

  &:hover {
    background-color: $primary;
  }
}

a {
  color: $primary;
  text-decoration: none;
}

@media screen and (min-width: 1024px) {
  .container {
    max-width: 1140px !important;
  }
}


html,
body {
  height: 100%;
}

body {
  min-width: 320px;
  background-color: $bodyBg !important;
  font-family: 'Poppins', sans-serif !important;
  color: $text-color !important;
  font-size: 16px;
  line-height: 28px !important;
  font-weight: 400;
  position: relative;

  // Styles For marketing page 
  &.home {
    background-color: white !important;

    .header {
      border-bottom: 0;
    }
  }
}

main {
  position: relative;
}

input {
  height: 50px;
  border-radius: 8px;
  padding: 8px 15px;
  color: $text-color;

  &::placeholder {
    color: #CACACA;
  }

  &:focus {
    box-shadow: 0 0 0 0.25rem rgba(108, 92, 231, 0.10);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

#root {
  // max-width: 1800px;
}

button,
input {
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
}

input[type="text"] {
  cursor: text;
}

a {
  color: $primary;
  text-decoration: none !important;
}

.container {
  @media screen and (max-width: 767px) {
    padding: 0 15px !important;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Noto+Nastaliq+Urdu:wght@400..700&display=swap');
