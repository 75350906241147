.order {
  display: flex;
  justify-content: center;
  align-items: center;
}

.order .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px !important;
  padding: 3rem 2rem;
}

.order .container .redirect-btn {
  height: 2.5rem;
  width: 8rem;
  margin-top: 2rem;
}
.order .container .order-header {
  margin: 0 0 1rem 0;
  padding: 30px;
}

.order .container .info {
  margin: 0 0 1rem 0;
  font-size: 1rem;
}

.order .container .info span {
  font-weight: bold;
}

.order .container .info a {
  color: #4e40cb;
}

.order .container .order-header img {
  width: 7rem;
}

.order-form .row {
  margin: 1rem 0;
}

.order-form .row .heading {
  font-size: 1.3rem;
  margin: 1rem 0 0.8rem 0;
  padding: 0;
}

.order-form .row .input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
}

.input-wrapper textarea {
  width: 100%;
  resize: vertical;
  height: 10rem;
  padding: 0.5rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.input-wrapper p {
  font-size: 14px;
  font-weight: bold;
  color: #4e40cb;
  align-self: flex-start;
  margin: 15px 0 5px 0;
}

.input-wrapper .radio-group {
  width: 100%;
  display: flex;
  gap: 2rem;
  margin-top: 15px;
}

.input-wrapper .radio-group-col {
  flex-direction: column-reverse;
  gap: 1rem;
}

.add-address {
  margin-top: 0.6rem;
  padding-top: 1.4rem;
  border-top: 1px solid;
}

.input-wrapper .radio-group input[type="radio"] {
  min-width: 1rem;
  height: 1rem;
  background-color: white;
  border-radius: 50%;
  border: 0.13rem solid white;
  box-shadow: 0 0 0 0.13rem #4e40cb;
  -webkit-appearance: none;
  padding: 0;
}


.input-wrapper .radio-group input[type="radio"]:checked {
  --bs-form-check-bg-image: none;
  background-color: #4e40cb !important;
}

.input-wrapper .radio-group label {
  font-size: 16px;
  font-weight: bold;
  color: black !important;
}

.input-wrapper .btn-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  margin: 0.5rem 0 0 0;
}

.input-wrapper .btn-section button {
  padding: 0.6rem 1rem 0.4rem;
}

.input-wrapper .btn-section .audio-recorder {
  border-radius: 10px;
  width: auto;
  padding: 2px;
}

.input-wrapper .disable {
  pointer-events: none;
  opacity: 0.4;
}

.input-wrapper .audio-section {
  align-self: flex-end;
  display: flex;
  align-items: flex-end;
  margin: 0.5rem 0 0 0;
}

.input-wrapper .audio-section .audio-play {
  height: 2rem;
}

.input-wrapper .audio-section .audio-delete {
  border: none;
  background: none !important;
  padding: 0;
}

.input-wrapper .audio-section .audio-delete img {
  background: none !important;
  vertical-align: sub;
}

.order-form .row .submit-btn {
  /*padding: 0.8rem 3rem;*/
  height: 3rem;
  width: 10rem;
}

button {
  border: none;
  border-radius: 0.2rem;
  color: white;
  background: #4e40cb;
  font-size: 20px;
  letter-spacing: 0.08rem;
}

.order .container .info a:hover, button:hover {
  filter: brightness(90%);
}

button, select, label {
  cursor: pointer;
}

button img {
  width: 1.5rem;
}


.blink img {
  animation: blinker 1s infinite;
}

@keyframes blinker {
  from {
    filter: invert(0%);
  }
  50% {
    filter: invert(100%);
  }
  to {
    filter: invert(0%);
  }
}


.error {
  font-size: 14px;
  color: red;
  margin: 0.5rem 0 0 0;
}

.info-msg, .error-msg {
  text-align: center;
  font-size: 16px;
}

.error-msg {
  color: red;
}

.menu {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.menu a {
  cursor: pointer;
  color: black;
  border: 1px solid;
  padding: 0.2rem 0.4rem;
  text-decoration: none;
}

.menu-images a:hover {
  color: #4e40cb;
}

.mt0 {
  margin-top: 0 !important;
}

.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dropbtn {
  letter-spacing: normal;
  font-size: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid black;
  color: black;
  background: transparent;
  border-radius: 0;
  padding: 0 0 0.5rem 0.8rem;
}

.dropdown-content {
  width: 100%;
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-options {
  max-height: 200px;
  overflow: scroll;
}

.dropdown-content span {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content span:hover {
  background: lightgray;
}

.show {
  display: block;
}

.arrow-down {
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  margin: 2px 5px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}


.search {
  box-sizing: border-box;
  font-size: 16px;
  width: 100%;
  padding: 10px 15px;
  outline: none;
  border: none;
  background: lightgray;
}

.search:focus {
  background: #d1cdfa;
}

.errorHolder {
  margin-bottom: 10px;
}

.form-check.disabled {
  background-color: lighten(#828282, 30%);
  cursor: not-allowed;
  opacity: 0.3;
  border-radius: 8px;
}

.form-check.disabled:active {
  pointer-events: none;
}

.tooltip-arrow::before{
  border-top-color: #E8E8E8 !important;
}

.form-check-label {
  transform: translateY(-5%);
}

.row .text-danger {
  padding-left: 0px;
}

.loader{
  display: flex;
  align-items: center;
  margin-right: auto;
  font-size: 15px;

  .text {
    margin-right: 8px;
  }
  
}

.transWrap{
  display: block;
  direction: rtl;
  font-size: 14px;
  color: #888;
  padding: 10px 15px;
  position: relative;
  background-color: white;
}

.retry {
  cursor: pointer;
  margin-bottom: 5px;
  margin-left: 5px;
}
